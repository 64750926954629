<template>
  <v-layout column wrap>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF DELINQUENT ACCOUNT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="list_of_delinquent_accounts"
        :search="search"
        :loading="data_table_loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-select
              v-model="category"
              class="mx-3 pt-5"
              :items="['1 year and above last due date','3 months and above last due date']"
              item-value="id"
              item-text="category"
              label="Category"
              required
              @change="get_list_of_delinquent_accounts_items"
              :rules="rules.combobox_rule"
            ></v-select>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <span class="subtitle-1">Total:  {{total}}</span>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <span class="headline" v-if="can_print">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>

            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearchOutline"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr
            :key="item.id"
          >
            <td>{{ item.type_of_loan }}</td>
            <td>
              {{ item.application_no }}
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.terms }}
            </td>
            <td>
              {{ item.currency_balance }}
            </td>
            <td>
              {{ item.amount_due }}
            </td>
            <td>
              {{ item.due_date }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-layout>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import moment from "moment";
  import {mdiAccountSearchOutline, mdiPrinter} from '@mdi/js'

  export default {
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
          mdiPrinter,
        },
      }
    },
    data() {
      return {
        total: 0,
        total: 0,
        can_print: false,
        category: '',
        list_of_delinquent_accounts: [],
        //table props
        search: '',
        data_table_loading: false,
        headers: [
          {text: 'Type', value: 'id'},
          {text: 'App. #', value: 'members.cmf_no'},
          {text: 'Name', value: 'members.last_name'},
          {text: 'Terms', value: 'members.first_name'},
          {text: 'Balance', value: 'amount'},
          {text: 'Premium', value: 'amount'},
          {text: 'Due Date', value: 'amount'},
        ],
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['company_logo']),
      ...mapGetters('authentication', ['branch_id', 'branch', 'branch_address', 'branch_contact_no', 'month_of', 'month_end']),
    },
    watch: {
      branch_id: function () {
        this.can_print = false
        this.category = ''
        this.list_of_delinquent_accounts = []
        this.total = '0'
      }
    },
    methods: {
      ...mapActions('members_information', ['list_of_deliquent_account']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      get_list_of_delinquent_accounts_items() {
        this.data_table_loading = true
        const data = new FormData()
        data.append('branch_id', this.branch_id);
        data.append('month_of', this.month_of);
        data.append('month_end', this.month_end);
        data.append('is_new_month', 0);
        data.append('type_of_loan', 'All');
        data.append('collector_id', 0);
        data.append('prev_months', 2);
        if (this.category === '3 months and above last due date') {
          data.append('prev_months', 1);
        }
        this.list_of_deliquent_account(data)
          .then((response) => {
            this.can_print = true
            this.list_of_delinquent_accounts = response.data[0].data
            this.total = response.data[0].total
            this.data_table_loading = false
          })
          .catch(error => {
            console.log(error)
          })
      },
      print_data() {
        var imgData = this.company_logo
        var array = []
        var month = this.category

        array.push(
          [
            {text: 'Type', alignment: 'center', style: 'label'},
            {text: 'App. #', alignment: 'center', style: 'label'},
            {text: 'Name', alignment: 'center', style: 'label'},
            {text: 'Terms', alignment: 'center', style: 'label'},
            {text: 'Balance', alignment: 'center', style: 'label'},
            {text: 'Premium', alignment: 'center', style: 'label'},
            {text: 'Due Date', alignment: 'center', style: 'label'},
          ]
        )
        if (this.list_of_delinquent_accounts.length > 0) {
          var total_amount = this.total
          this.list_of_delinquent_accounts.forEach(function (item, index, payment) {
            array.push(
              [
                {text: item.type_of_loan, alignment: 'center'},
                {text: item.application_no, alignment: 'left'},
                {text: item.name, alignment: 'left'},
                {text: item.terms, alignment: 'center'},
                {text: item.currency_balance, alignment: 'center'},
                {text: item.amount_due, alignment: 'center'},
                {text: item.due_date, alignment: 'center'},
              ]
            )
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    color: 'red',
                    text: 'TOTAL: ',
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                  {
                    color: 'red',
                    text: total_amount,
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                ]
              )
            }
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LIST OF DELINQUENT ACCOUNT', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            {text: 'Category: ' + month, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [60, 50, 130, 30, 40, 40, 70],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
